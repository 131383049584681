/** @jsx jsx */
import { jsx, Styled, useThemeUI } from 'theme-ui'
import { Palette } from '@uswitch/trustyle-utils.palette'
import { ContentfulModules } from '@h/modules'
import { cf2imgix } from '@h/imgix-image'
import ImgixImg from '@e/imgix-img'
import sw from '@u/switch'
import { toCamelCase } from '@u/string'
import composeVariants from '@u/styled-variants'
import { useSelector } from 'react-redux'

const ImageBackgroundLayout = ({ header, paragraph, modules, imgBackground, options = {} }) => {
  const { theme } = useThemeUI()
  const imageParams = {
    blend: theme.colors['hero-solid'],
    'blend-mode': 'hue',
    sat: -20,
    'blend-alpha': 80,
  }

  const background = imgBackground
    ? `url(${cf2imgix(imgBackground.file.url, imageParams)}) no-repeat center center`
    : null

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        backgroundColor: 'hero',
        variant: 'modules.content-hero-module',
        ...options,
      }}
    >
      <div
        sx={{
          height: '100%',
          width: '100%',
          zIndex: -100,
          position: 'absolute',
          background,
          backgroundSize: 'cover',
        }}
      />
      <div
        sx={{
          width: 'elements.hero.width',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          ...composeVariants(
            [
              ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
              ['elements.hero.sm', 'elements.hero.md', 'elements.hero.lg'],
              'modules.content-hero-module.content',
            ],
            theme,
          ),
        }}
      >
        <Styled.h1 sx={{ pb: 0, maxWidth: ['grid.sm.4', 'grid.md.7', 'grid.lg.9'] }}>
          {header}
        </Styled.h1>
        <If condition={paragraph}>
          <Styled.h4 sx={{ maxWidth: ['grid.sm.4', 'grid.md.7', 'grid.lg.8'] }}>
            {paragraph}
          </Styled.h4>
        </If>

        <ContentfulModules modules={modules} />
      </div>
    </div>
  )
}

// This component will able to display the component as a background image (saveOn)
// or with the image on the right hand side (uswitch pre rebrand).
const FlexLayout = ({ header, paragraph, modules, imgBackground, options = {} }) => {
  const { theme } = useThemeUI()
  const imageParams = {
    blend: theme.colors['hero-solid'],
    'blend-mode': 'hue',
    sat: -20,
    'blend-alpha': 80,
  }

  const background = imgBackground
    ? `url(${cf2imgix(imgBackground.file.url, imageParams)}) no-repeat center center`
    : null

  return (
    <div
      sx={{
        width: '100%',
        backgroundColor: 'hero',
        position: 'relative',
        variant: 'modules.content-hero-module',
        ...options,
      }}
    >
      <div sx={{ variant: ['layout.container.sm', 'layout.container.md', 'layout.container.lg'] }}>
        <div>
          <Styled.h1 sx={{ variant: 'modules.content-hero-module.h1' }}>{header}</Styled.h1>
          <If condition={paragraph}>
            <Styled.h4 sx={{ variant: 'modules.content-hero-module.h4' }}>{paragraph}</Styled.h4>
          </If>
        </div>

        <div sx={{ variant: 'modules.content-hero-module.content' }}>
          <div sx={{ variant: 'modules.content-hero-module.content.modules' }}>
            <ContentfulModules modules={modules} />
          </div>
          <div sx={{ background, variant: 'modules.content-hero-module.content.image' }} />
        </div>
      </div>
    </div>
  )
}

const HeaderOutOfForm = ({ header, paragraph, modules = [], imgBackground, options = {} }) => {
  const { imageParams = {} } = useSelector(
    state => state.app.meta?.contentHeroModule?.headerOutOfForm || {},
  )
  return (
    <Palette
      as='div'
      sx={{ backgroundColor: 'hero', overflow: 'hidden' }}
      px={{ backgroundColor: 'backgroundColor' }}
    >
      <div
        sx={{
          variant: ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
          position: 'relative',
          pt: paragraph || modules.length ? 0 : 'lg',
          pb: 'lg',
        }}
      >
        <div>
          <div sx={{ position: ['relative', 'unset'], py: ['lg', 'md'] }}>
            <div sx={{ position: 'relative', zIndex: 10 }}>
              <Palette
                as='h1'
                sx={{
                  width: ['75%', 'grid.md.6', 'grid.lg.7'],
                  mt: 0,
                  pt: [0, 'sm'],
                  fontSize: 'xl',
                  variant: 'modules.content-hero-module.header-out-of-form.header',
                }}
                px={{ color: 'featureTextColor' }}
              >
                {header}
              </Palette>
              <If condition={paragraph}>
                <Palette
                  as='h4'
                  sx={{
                    width: ['50%', 'grid.md.6', 'grid.lg.5'],
                    my: 0,
                    fontSize: ['sm', 'md'],
                    fontWeight: ['base', 'normal'],
                    variant: 'modules.content-hero-module.header-out-of-form.sub-header',
                  }}
                  px={{ color: 'accentTextColor' }}
                >
                  {paragraph}
                </Palette>
              </If>
            </div>

            <If condition={imgBackground && imgBackground.file}>
              <ImgixImg
                critical
                src={imgBackground.file.url}
                alt={imgBackground.description}
                sx={{
                  position: 'absolute',
                  maxWidth: [null, '100%', '100%'],
                  width: 'auto',
                  height: ['90%', 'auto'],
                  maxHeight: [230, '90%', '90%'],
                  bottom: 0,
                  right: [-45, -100, 0],
                  variant: 'modules.content-hero-module.header-out-of-form.image',
                }}
                {...imageParams}
              />
            </If>
          </div>

          <div
            sx={{
              width: ['100%', 'grid.md.6', 'grid.lg.4'],
              position: 'relative',
              zIndex: 10,
              variant: 'modules.content-hero-module.form',
            }}
          >
            <ContentfulModules modules={modules} />
          </div>
        </div>
      </div>
    </Palette>
  )
}

/*
 * @TODO: This is a specific hero for the /telecoms-awards page for 2020. The refactor for heros
 * needs to take the flexibility of visual design for this component into consideration.
 */
const TelecomsAwards = ({ header, paragraph, modules, imgBackground, options = {} }) => (
  <Palette
    as='div'
    sx={{ backgroundColor: 'hero', overflow: 'hidden' }}
    px={{ backgroundColor: 'backgroundColor' }}
  >
    <div
      sx={{
        variant: ['layout.container.sm', 'layout.container.md', 'layout.container.lg'],
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        minHeight: [219, 344],
      }}
    >
      <div sx={{ py: ['lg', 'md'] }}>
        <div sx={{ position: 'relative', zIndex: 10 }}>
          <Palette
            as='h1'
            sx={{
              width: ['75%', 'grid.md.6', 'grid.lg.4'],
              mt: 0,
              pt: 0,
              fontSize: ['lg', 'xxl'],
            }}
            px={{ color: 'featureTextColor' }}
          >
            {header}
          </Palette>
          <If condition={paragraph}>
            <Palette
              as='h4'
              sx={{
                width: ['50%', 'grid.md.6', 'grid.lg.5'],
                my: 0,
                fontSize: 'sm',
                fontWeight: ['base', 'normal'],
              }}
              px={{ color: 'accentTextColor' }}
            >
              {paragraph}
            </Palette>
          </If>
        </div>
      </div>

      <If condition={imgBackground && imgBackground.file}>
        <ImgixImg
          src={imgBackground.file.url}
          alt={imgBackground.description}
          sx={{
            maxWidth: '100%',
            width: 'auto',
            maxHeight: '100%',
            height: 'auto',
            lineHeight: 0,
            position: 'absolute',
            bottom: 0,
            top: 0,
            right: [-10, 50],
          }}
        />
      </If>
    </div>
  </Palette>
)

export default params =>
  sw({
    headerOutOfForm: HeaderOutOfForm,
    telecomsAwards: TelecomsAwards,
    imageBackground: ImageBackgroundLayout,
    default: FlexLayout(params),
  })(params.layout ? toCamelCase(params.layout) : params.layout, params)

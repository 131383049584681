import connect from '@h/connect-redux'
import { getModuleFromIncludes, includes } from '@r/contentful-selectors'
import { contentId } from '@u/contentful'
import Hero from '@m/content-hero-module'

export default connect((state, props) => {
  if (!props.imgBackground) return {}

  const { fields: imgBackground = {} } = getModuleFromIncludes(
    includes(state),
    contentId(props.imgBackground),
  )
  return { imgBackground }
})(Hero)
